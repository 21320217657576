/**
 *  @file
 *  Defines the routes of the app
 *
 *  NOTE: Empty authorizedRoles means that EVERYONE can ACCESS
 *
 */

import ROLES from './Roles'

const Routes = {}

Routes.Activate = { url: '/activate', title: 'navigation:title.activate' }
Routes.Register = {
  url: '/manager-registration',
  title: 'navigation:title.register',
}
Routes.Login = { url: '/login', title: 'navigation:title.login' }
Routes.ResetPassword = {
  url: '/reset-password',
  title: 'navigation:title.resetPassword',
}
Routes.RegisterAdmin = {
  url: '/admin-registration',
  title: 'navigation:title.registerAdmin',
}

// Dashboard
Routes.Dashboard = {
  url: '/',
  title: 'navigation:title.dashboard',
  component: {
    authorizedRoles: [],
    pageName: 'Dashboard',
    // pageName: 'Blank',
    mounted: null,
    routerIsExactPath: true,
    link: { text: 'Dashboard', inSidebar: true },
  },
}

// User account
Routes.Account = {
  url: '/account',
  title: 'navigation:titles.account',
  component: {
    authorizedRoles: [],
    pageName: 'Account',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Account', inSidebar: false },
  },
}

// Help
Routes.Help = {
  url: '/help',
  title: 'navigation:title.help',
  component: {
    authorizedRoles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.MANAGER],
    pageName: 'Help',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Help', inSidebar: false },
  },
}

// Device Telemetry
Routes.DeviceTelemetry = {
  url: '/device-telemetry',
  title: 'navigation:title.deviceTelemetry',
  component: {
    authorizedRoles: [ROLES.ADMIN],
    pageName: 'DeviceTelemetry',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Device Telemetry', inSidebar: true },
  },
}

Routes.PreScreening = {
  url: '/pre-screening',
  title: 'navigation:title.preScreening',
  component: {
    authorizedRoles: [ROLES.ADMIN],
    pageName: 'PreScreening',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Pre Screening', inSidebar: true },
  },
}

// Options
Routes.Options = {
  url: '/options',
  title: 'navigation:title.options',
  component: {
    authorizedRoles: [],
    pageName: 'Options',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Options', inSidebar: false },
  },
}

// Plant
Routes.Plant = {
  url: '/plant',
  title: 'navigation:title.plant',
  component: {
    authorizedRoles: [],
    pageName: 'Plant',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Plant', inSidebar: false },
  },
}

// Reports
Routes.Reports = {
  url: '/reports',
  title: 'navigation:title.reports',
  component: {
    authorizedRoles: [ROLES.MANAGER],
    pageName: 'Reports',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Reports', inSidebar: true },
  },
}
Routes.Reports.Detail = { url: `${Routes.Reports.url}/:reportId` }

// Groups
Routes.Groups = {
  url: '/groups',
  title: 'navigation:title.groups',
  component: {
    authorizedRoles: [ROLES.ADMIN, ROLES.MANAGER],
    pageName: 'Groups',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Groups', inSidebar: true },
  },
}
Routes.Groups.Detail = { url: `${Routes.Groups.url}/:groupId` }

// Users
Routes.Users = {
  url: '/users',
  title: 'navigation:title.users',
  component: {
    authorizedRoles: [ROLES.ADMIN],
    pageName: 'Users',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Users', inSidebar: true },
  },
}
Routes.Users.Profile = { url: `${Routes.Users.url}/:usersId` }

// Tasks
Routes.Tasks = {
  url: '/tasks',
  title: 'navigation:title.tasks',
  component: {
    authorizedRoles: [ROLES.ADMIN],
    pageName: 'Tasks',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Tasks', inSidebar: true },
  },
}
Routes.Tasks.Detail = { url: `${Routes.Tasks.url}/:taskId` }

// Surveys
Routes.Surveys = {
  url: '/surveys',
  title: 'navigation:title.surveys',
  component: {
    authorizedRoles: [ROLES.ADMIN],
    pageName: 'Surveys',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Surveys', inSidebar: true },
  },
}

// Worklog
Routes.Worklog = {
  url: '/worklog',
  title: 'navigation:title.worklog',
  component: {
    authorizedRoles: [ROLES.MANAGER],
    pageName: 'Worklog',
    mounted: null,
    routerIsExactPath: false,
    link: { text: 'Worklog', inSidebar: true },
  },
}
Routes.Worklog.Detail = { url: `${Routes.Worklog.url}/:worklogId` }

export default Routes
