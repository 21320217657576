import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { Endpoints } from '../../../constants'
import { ServerConnStatus } from '../../atoms/Icons'
import { appUiSelector } from '../../../redux/ui/ui.selectors'

// import colors
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'

const mapStateToProps = (state) => ({
  isLeftSidebarCollapsed: appUiSelector(state).isLeftSidebarCollapsed,
})

const styles = (theme) => ({
  root: {
    position: 'relative',
    bottom: 0,
    padding: '0px 1.5rem',
    background: theme.palette.background.default,
    height: 34,

    fontSize: 11,
    textAlign: 'right',
    color: '#666',
    marginBottom: 12,

    '&.collapsed': {
      left: 74,
      width: 'calc(100% - 74px)',
    },
    '&.expanded': {
      left: 264,
      width: 'calc(100% - 264px)',
    },
  },
  companyLogoStyle: {
    height: 26,
    verticalAlign: 'baseline',
    marginLeft: 6,
  },
  statusStyle: {
    fontWeight: '500',
    fontSize: 12,
  },
  iconStyle: {
    position: 'relative',
    top: '-1px',
    marginLeft: '4px',
    marginRight: '2px',
    fontSize: 11,
    verticalAlign: 'middle',
    '&.online': {
      color: green[500],
    },
    '&.offline': {
      color: red[500],
    },
  },
})

class Footer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isLeftSidebarCollapsed: PropTypes.bool.isRequired,
  }

  state = {
    online: true,
  }

  componentDidMount() {
    this.timer = setInterval(() => this.pingServer(), 60000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)

    this.timer = null
    this.unmount = true
  }

  pingServer() {
    return fetch(Endpoints.Ping.Urls.Base)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          !this.unmount && this.setState({ online: true })
        } else {
          !this.unmount && this.setState({ online: false })
        }
      })
      .catch(() => !this.unmount && this.setState({ online: false }))
  }

  render() {
    const { classes, isLeftSidebarCollapsed } = this.props
    const { online } = this.state

    return (
      <footer
        className={[
          classes.root,
          isLeftSidebarCollapsed ? 'collapsed' : 'expanded',
        ].join(' ')}
      >
        <Typography
          align="right"
          size="11"
          variant="caption"
          display="inline"
          gutterBottom
          className={classes.statusStyle}
        >
          WearHealth © {new Date().getFullYear()} | v
          {process.env.REACT_APP_VERSION} |
          <ServerConnStatus
            className={[classes.iconStyle, online ? 'online' : 'offline'].join(
              ' ',
            )}
          />
          Service {online ? 'online' : 'offline'}
        </Typography>
      </footer>
    )
  }
}

const styled = withStyles(styles)(Footer)
const connected = connect(mapStateToProps)(styled)

export default withTranslation()(connected)
