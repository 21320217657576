import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'
import {
  Grid,
  Paper,
  CssBaseline,
  withStyles,
  Typography,
} from '@material-ui/core'
import { GuestTheme } from '../themes'
// import PublicBackground from '../../images/login-screen-bg-filtered.jpg'
import companyLogo from '../../images/wh-logo-inverted.svg'
import graphBg from '../../images/graph-background.svg'

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
  },
  split: {
    paddingRight: 48,
    color: '#fff',
    background: `url(${graphBg}) no-repeat, linear-gradient(136deg, rgba(40,148,231,1) -40%, rgba(5,82,181,1) 56%)`,
    backgroundSize: 'cover',
    backgroundPositionY: 'bottom',
    textAlign: 'right',
  },

  splitLogin: {
    paddingTop: 64 + 38 + 96,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginBottom: 48,
    zIndex: 2,
  },
  companyLogoStyle: {
    height: 14,
    verticalAlign: 'baseline',
    marginLeft: 6,
  },
  particlesWrapperStyle: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    zIndex: 1,
  },
  footerContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: 4,
    width: '100%',
    height: 34,
    lineHeight: '26px',
    fontSize: 11,
    color: '#666',
    zIndex: 2,
  },
  appLogoStyle: {
    marginTop: 64,
    marginBottom: 96,
    height: 38,
  },
  logoDescriptionStyles: {
    fontSize: '4.375rem',
    letterSpacing: '-.0025rem',
    lineHeight: 1.1,
    fontWeight: 300,
  },
})

class PublicRoute extends React.Component {
  static propTypes = {
    component: PropTypes.any,
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { classes, component: Component, ...rest } = this.props

    return (
      <Route
        {...rest}
        render={(props) => (
          <GuestTheme>
            <CssBaseline />
            <Grid container component="main" className={classes.root}>
              <Grid item xs={false} sm={4} md={6} className={classes.split}>
                <img
                  src={companyLogo}
                  className={classes.appLogoStyle}
                  alt="WearHealth"
                />
                <Typography
                  display="block"
                  variant="h2"
                  className={classes.logoDescriptionStyles}
                  gutterBottom
                >
                  Safety and Health <br /> Intelligence
                </Typography>
                <Typography variant="h5">
                  Continuous. Objective. Proactive.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                component={Paper}
                elevation={6}
                square
                className={classes.splitLogin}
              >
                <Component {...props} />
              </Grid>
            </Grid>
          </GuestTheme>
        )}
      />
    )
  }
}

const styled = withStyles(styles)(PublicRoute)

export default styled
