import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveCalendarCanvas } from '@nivo/calendar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
// import { DEFAULT_TIME_FORMAT } from '../../../utils/config'
import CalendarLegends from './CalendarLegends'
import palette from '../../themes/styles/palette'
import {
  physicalCalendarLegend,
  mentalCalendarLegend,
  sessionsCalendarLegend,
} from './legends.constant'

const { cyan, orange, green, red, yellow, primary } = palette
const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    zIndex: 999,
    top: 'calc(50% + -24px)',
    left: 'calc(50% - 8px)',
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const CalendarChart = (props) => {
  const { loaded, data, minDate, maxDate, type } = props
  const classes = useStyles()
  const currentYear = dayjs().year()
  const margins = { top: 80, right: 24, bottom: 0, left: 24 }

  let colors = []

  let legends = {
    anchor: 'top-right',
    direction: 'row',
    translateY: -40,
    itemCount: 3,
    itemWidth: 42,
    itemHeight: 42,
    itemsSpacing: 14,
    itemDirection: 'right-to-left',
  }

  let legendLabels = []

  if (type === 'physical') {
    legendLabels = physicalCalendarLegend

    legends.itemCount = 5

    colors = [
      cyan['A400'],
      green['A700'],
      yellow['600'],
      orange['A200'],
      red['A200'],
    ]
  }

  if (type === 'mental' || type === 'workload') {
    legendLabels = mentalCalendarLegend
    legends.itemCount = 3
    colors = [
      green['A700'],
      green['A700'],
      yellow['600'],
      yellow['600'],
      red['A200'],
    ]
  }

  if (type === 'sessions') {
    legendLabels = sessionsCalendarLegend
    legends.itemCount = 1
    colors = [primary[600]]
  }

  const config = {
    height: 180 + margins.top,
  }

  return (
    <>
      <CalendarLegends legends={legendLabels} />
      {loaded ? (
        <div style={{ position: 'relative', height: config.height }}>
          <ResponsiveCalendarCanvas
            data={data || []}
            from={minDate || `${currentYear}-01-01`}
            to={maxDate || `${currentYear}-12-31`}
            emptyColor="#eeeeee"
            colors={colors}
            margin={margins}
            align="top"
            minValue={0}
            maxValue={10}
            yearSpacing={40}
            pixelRatio={2}
            daySpacing={4}
            monthBorderColor="#ffffff"
            dayBorderWidth={0}
            dayBorderColor="#ffffff"
            tooltip={({ day, value, color }) => (
              <>
                {dayjs(day).format('DD.MM.YYYY')}: <strong>{value}</strong>
              </>
            )}
          />
        </div>
      ) : (
        <div style={{ position: 'relative', height: config.height }}>
          <ResponsiveCalendarCanvas
            data={[]}
            opacity={0.3}
            from={`${currentYear}-01-01`}
            to={`${currentYear}-12-31`}
            emptyColor="#f8f8f8"
            colors={['#f8f8f8']}
            className={classes}
            style={{ opacity: 0.54 }}
            margin={margins}
            align="top"
            minValue={0}
            maxValue={10}
            yearSpacing={40}
            daySpacing={4}
            monthBorderColor="#ffffff"
            dayBorderWidth={0}
            dayBorderColor="#ffffff"
            legends={[legends]}
          />

          <div className={classes.loading}>
            <CircularProgress />
          </div>
        </div>
      )}
    </>
  )
}

CalendarChart.propTypes = {
  loaded: PropTypes.bool.isRequired,
  data: PropTypes.array,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  type: PropTypes.string,
}

export default CalendarChart
