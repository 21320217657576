import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { fetchWithErrors, withParams } from '../../../../utils'
import { _ } from '../../../../lib'
import { Endpoints } from '../../../../constants'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import CalendarChart from '../../charts/CalendarChart'
import { formatCalendarData } from '../../../../utils/data-formatter'
import { connect } from 'react-redux'
import { compose } from 'redux'

const styles = (theme) => ({
  paper: {
    position: 'relative',
    borderRadius: '0px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
})

class WorkloadCalendarWidget extends Component {
  state = {
    fetchError: false,
    fetching: false,
    loaded: false,
    workload: {
      data: [],
    },
  }

  async componentDidMount() {
    await this.getAnalytics()
  }

  async componentDidUpdate(prevProps, prevState) {
    const { groupCode: prevPropsGroupCode } = prevProps

    const { groupCode: propsGroupCode } = this.props

    if (prevPropsGroupCode !== propsGroupCode) {
      await this.getAnalytics()
    }
  }

  getAnalytics = async () => {
    const workload = await this.fetchAnalytics()

    const oldState = _.cloneDeep(this.state)

    if (workload !== undefined) {
      !this.unmount &&
        this.setState({
          ...oldState,
          fetching: false,
          loaded: true,
          workload,
        })
    } else {
      !this.unmount &&
        this.setState({
          ...oldState,
          fetching: false,
          fetchError: true,
          workload: { workload: [] },
        })
    }
  }

  fetchAnalytics = async (type) => {
    const oldState = { ...this.state }
    !this.unmount && this.setState({ ...oldState, fetching: true })

    const response = await fetchWithErrors(
      withParams(Endpoints.Analytics.Urls.WorkloadCalendar, {
        type: type,
        group_code:
          this.props.groupCode === 'all' ? null : this.props.groupCode,
        year: dayjs().year(),
      }),
    )

    if (!('error' in response)) {
      return formatCalendarData(response.response)
    } else {
      !this.unmount &&
        this.setState({
          ...oldState,
          fetching: false,
          fetchError: true,
        })
    }
  }

  componentWillUnmount() {
    this.unmount = true
  }

  triggerViewError = (error) => {
    if (error) {
      this.setState({
        fetching: false,
        fetchError: true,
      })
    }
  }

  render() {
    const { t, classes } = this.props

    return (
      <Paper className={[classes.paper, classes.graphPaper].join(' ')}>
        <Typography variant="h6" align="left">
          {`${t('workload')} ${t('heatmap')}`}
        </Typography>
        <CalendarChart
          type="workload"
          loaded={!this.state.fetching}
          data={this.state.workload.data}
          minDate={this.state.workload.minDate}
          maxDate={this.state.workload.maxDate}
        />
      </Paper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    groupCode: state.dashboard.groupCode,
  }
}

WorkloadCalendarWidget.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  groupCode: PropTypes.string.isRequired,
}

export default compose(
  connect(mapStateToProps, null),
  withRouter,
  withStyles(styles),
  withTranslation(['global', 'dashboard']),
)(WorkloadCalendarWidget)
