import PropTypes from 'prop-types'
import React, { Suspense, Component, lazy } from 'react'
import { Route, Switch, Link, withRouter } from 'react-router-dom'
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { Endpoints, Routes } from '../../../../constants'
import ActivateSubview from './ActivateSubview'
import RegisterAdminSubview from './RegisterAdminSubview'
import ModalBox from '../../ModalBox'
import {
  isAuthenticated,
  authenticate,
  fetchWithErrors,
} from '../../../../utils'
import { SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { setUserPermissions } from '../../../../redux/user/user.actions'
import { adminRegister } from '../../../../api/api.auth'
import { _ } from '../../../../lib'
import * as jwtDecode from 'jwt-decode'

const styles = (theme) => ({
  appLogoStyle: {
    display: 'block',
    width: '204px',
    margin: '0 auto',
  },
  gridContainerStyle: {
    height: '100%',
  },

  formActionsContainerStyle: {
    textAlign: 'right',
    marginTop: '48px',
  },
  logoDescriptionStyles: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1.1em',
    color: '#ccc',
  },
  legalText: {
    fontSize: '12px',
    textAlign: 'justify',
    lineHeight: '1.25rem',
  },
  legalTextContainerStyle: {
    marginTop: '12px',
    width: '428px',
  },
  resetPasswordButtonStyles: {
    float: 'left',
  },
})

// Load routes only matching ROLE
const LoginSubview = lazy(() =>
  import(
    /* webpackChunkName: "page-chunk-login" */
    './LoginSubview'
  ),
)

const RegisterSubview = lazy(() =>
  import(
    /* webpackChunkName: "page-chunk-register" */
    './RegisterSubview'
  ),
)

class AuthenticationView extends Component {
  state = {
    showTabBar:
      this.props.location.pathname === Routes.Register.url ||
      this.props.location.pathname === Routes.Login.url,
    submitting: false,
    submitError: false,
    openDialog: false,
  }

  componentDidMount() {
    const { history } = this.props

    if (isAuthenticated()) {
      history.push(Routes.Dashboard.url)
    }
  }

  handleAdminRegistration = async ({
    repeat_password: RepeatPassword,
    ...restRegDetails
  }) => {
    const { history } = this.props
    const rootResponse = await adminRegister(restRegDetails)
    const enclosedResponse = rootResponse.response

    if (!('error' in rootResponse) && rootResponse.status !== 'error') {
      history.push(Routes.Login.url)
    } else {
      throw new SubmissionError(
        enclosedResponse || { _error: rootResponse.message },
      )
    }
  }

  handleUserLogin = async (authDetails) => {
    const { history, setPermissions } = this.props

    this.setState({ submitting: true })

    const rootResponse = await fetchWithErrors(Endpoints.Auth.Urls.Login, {
      noAuthorization: true,
      method: 'POST',
      body: JSON.stringify(authDetails),
    })

    if (rootResponse.status === 'error') {
      this.setState({ submitError: true, submitting: false })
    } else {
      const enclosedResponse = rootResponse.response
      const decodedToken = jwtDecode(enclosedResponse.token)

      setPermissions({
        decoded: decodedToken,
        authorization: enclosedResponse.token,
      })
      authenticate(enclosedResponse.token).then(() => {
        history.push(Routes.Dashboard.url)
      })
    }
  }

  handleUserRegistration = async (regDetails) => {
    const { history } = this.props

    this.setState({ submitting: true })

    const response = await fetchWithErrors(Endpoints.Auth.Urls.Register, {
      noAuthorization: true,
      method: 'POST',
      body: JSON.stringify(regDetails),
    })

    if (response.status === 'error') {
      this.setState({ submitError: true, submitting: false })
    } else {
      this.setState({ submitting: false })
      return history.push(Routes.Login.url)
    }
  }

  onCloseDialog = () => {
    const oldState = _.cloneDeep(this.state)

    this.setState({
      oldState,
      openDialog: false,
    })
  }

  render() {
    const { classes, location, t } = this.props
    const { submitting, submitError, openDialog } = this.state
    const searchParams = new URLSearchParams(location.search)

    return (
      <>
        {/* Modal needs to open when response from backend is UNAUTHORIZED_WEB_ACCESS */}
        <ModalBox
          className={classes.root}
          open={openDialog}
          type="error"
          onClose={this.onCloseDialog}
          noHeader
          actionButton={
            <Button onClick={this.onCloseDialog}>
              {t('close').toUpperCase()}
            </Button>
          }
        >
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Unauthorized user
            </Typography>

            <Typography variant="body1" align="center" gutterBottom>
              We detected an unauthorized login attempt. If you think you should
              have access please contact us at <u>support@wearhealth.com</u>.
            </Typography>
            <br />
          </>
        </ModalBox>
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
          className={classes.gridContainerStyle}
        >
          <Grid item xs={9}>
            {this.state.showTabBar && (
              <Tabs
                value={location.pathname}
                onChange={this.handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  to={Routes.Login.url}
                  value={Routes.Login.url}
                  label="Login"
                  component={Link}
                />
                <Tab
                  to={Routes.Register.url}
                  value={Routes.Register.url}
                  label="Register"
                  component={Link}
                />
              </Tabs>
            )}
            <Suspense fallback={'Loading'}>
              <Switch>
                <Route
                  exact
                  path={Routes.Login.url}
                  render={(props) => (
                    <LoginSubview
                      {...props}
                      onSubmit={this.handleUserLogin}
                      submitting={submitting}
                      submitError={submitError}
                    />
                  )}
                />
                <Route
                  exact
                  path={Routes.Register.url}
                  render={(props) => (
                    <RegisterSubview
                      {...props}
                      onSubmit={this.handleUserRegistration}
                      initialValues={{
                        email: searchParams.get('email')
                          ? decodeURI(searchParams.get('email'))
                          : '',
                        activation_token: searchParams.get('activation_token')
                          ? decodeURI(searchParams.get('activation_token'))
                          : '',
                      }}
                    />
                  )}
                />
                <Route
                  exact
                  path={Routes.Activate.url}
                  component={ActivateSubview}
                />
                <Route
                  exact
                  path={Routes.RegisterAdmin.url}
                  render={(props) => (
                    <RegisterAdminSubview
                      {...props}
                      onSubmit={this.handleAdminRegistration}
                      initialValues={{
                        email: decodeURI(searchParams.get('email')),
                        activation_token: decodeURI(
                          searchParams.get('activation_token'),
                        ),
                      }}
                    />
                  )}
                />
              </Switch>
            </Suspense>

            <div className={classes.legalTextContainerStyle}>
              <Typography
                variant="caption"
                gutterBottom
                className={classes.legalText}
              >
                <b>ATTENTION:</b> This system is restricted solely to WearHealth
                users for authorized business only. Any actual or attempted
                unauthorized access, use or modification of this system is
                strictly prohibited by WearHealth. Unauthorized users are
                subject to WearHealth disciplinary proceedings and/or criminal
                and civil penalties under state, federal or other applicable
                domestic and foreign laws. The use of this system may be
                monitored and recorded for administrative and security reasons.
                If such monitoring and/or recording reveal possible evidence of
                criminal activity, WearHealth may provide the evidence of such
                monitoring to law enforcement officials.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

AuthenticationView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setPermissions: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    permissions: state.user.permissions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPermissions: (payload) => dispatch(setUserPermissions(payload)),
  }
}

const translated = withTranslation(['global'])(AuthenticationView)
const styled = withStyles(styles)(translated)
const connected = connect(mapStateToProps, mapDispatchToProps)(styled)
const routed = withRouter(connected)

export default routed
